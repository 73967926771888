import "./AlbumPage.scss";

import React, { useEffect } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import HEADER from "../../../../assets/images/default/header_image.jpg";
import { useNavigate, useParams } from "react-router";
import { Col, Image, Row } from "antd";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const AlbumPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  // const albumID = params.albumId;
  console.log("params albumID ==> ", params.albumId);

  useEffect(() => {
    if (params.albumId === "test1") {
      navigate("/");
    }
  }, [navigate, params.albumId]);

  const galleryPhotos = [
    {
      id: 1,
      url: "https://prototechasia.com/wp-content/uploads/what-is-a-cnc-machine.jpeg",
      album: "album1",
    },
    {
      id: 3,
      url: "https://www.fastems.com/wp-content/uploads/2021/08/AdobeStock_172041052-scaled.jpeg",
      album: "album1",
    },
    {
      id: 5,
      url: "https://images.ctfassets.net/q2hzfkp3j57e/8ec9d07d6457651c625c89d3939a538aab4918f8/8c314a354fa973a907af1cac2da1b3f6/thumbnail_1.jpg?w=1600&h=1200&fm=jpg&q=82",
      album: "album1",
    },
    {
      id: 14,
      url: "https://lagunatools.com/wp-content/uploads/2021/11/iQ_Pro_shadow-768x896.webp",
      album: "album1",
    },
    {
      id: 6,
      url: "https://cdn-capli.nitrocdn.com/QsYZfHoaMIGTPRAErcQoIziLidBAbBPB/assets/images/optimized/rev-db006d2/www.3qmachining.com/wp-content/uploads/2021/12/What-is-a-CNC-Lathe-Machine-e1651310844416-1024x1024.jpg",
      album: "album1",
    },
    {
      id: 8,
      url: "https://www.fastems.com/wp-content/uploads/2021/08/AdobeStock_172041052-scaled.jpeg",
      album: "album1",
    },
    {
      id: 10,
      url: "https://images.prismic.io/xometry-marketing/0665ce07-ff7d-41f0-9388-dffba31426b7_cnc-milling-machine.jpg?auto=compress%2Cformat&rect=166%2C0%2C667%2C667&w=486&h=486&fit=max",
      album: "album1",
    },
    {
      id: 11,
      url: "https://tdhmfg.com/wp-content/uploads/2023/02/blog-cnc.jpg",
      album: "album1",
    },
    {
      id: 12,
      url: "https://lagunatools.com/wp-content/uploads/2021/11/iQ_Pro_shadow-768x896.webp",
      album: "album1",
    },
    {
      id: 13,
      url: "https://img.freepik.com/free-photo/modern-cnc-lathes-metalworking-industry_587448-4985.jpg?w=2000",
      album: "album1",
    },
  ];

  return (
    <div className="album-page">
      <PageHeader title={params.albumId} image={HEADER} />
      <Row>
        <Col
          className="menu-containe-col"
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 16, offset: 4 }}
        >
          <div className="album-container">
            <Image.PreviewGroup>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3 }}
              >
                <Masonry gutter="10px">
                  {galleryPhotos
                    ? galleryPhotos.map((album) => {
                        return (
                          <div
                            className="gallery-image-container"
                            key={album.id}
                          >
                            <Image src={album.url} />
                          </div>
                        );
                      })
                    : "Loading"}
                </Masonry>
              </ResponsiveMasonry>
            </Image.PreviewGroup>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AlbumPage;
