import './AboutUsPage.scss';
import { Col, Row } from 'antd';

import React from 'react';
import OffersCard from '../../../../components/OffersCard/OffersCard';
import { FundOutlined, BulbOutlined, StarOutlined } from '@ant-design/icons';
import HEADER from '../../../../assets/images/default/header_image.jpg';
import SERVICE1 from '../../../../assets/images/services/service1.jpeg';
import SERVICE2 from '../../../../assets/images/services/service2.jpeg';
import WorkingProcessCard from '../../../../components/WorkingProcessCard/WorkingProcessCard';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import HeadingElement from '../../../../components/HeadingElement/HeadingElement';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const isMobile = useCheckMobileScreen();
  const { t } = useTranslation();

  const offers = [
    {
      id: 1,
      title: t("aboutUs.offerTitle1"),
      description: t("aboutUs.offerDescription1"),
      icon: <FundOutlined />,
    },
    {
      id: 2,
      title: t("aboutUs.offerTitle2"),
      description: t("aboutUs.offerDescription2"),
      icon: <BulbOutlined />,
    },
    {
      id: 3,
      title: t("aboutUs.offerTitle3"),
      description: t("aboutUs.offerDescription3"),
      icon: <StarOutlined />,
    },
  ];

  const processes = [
    {
      id: 1,
      title: 'DEFINE DESIGN',
      description:
        'Lorem ipsum dolor sit amet, cons aring elit sed dllao the eimod tempor inciunt ullaco laboris aliquip alora.',
      img: SERVICE1,
      direction: '',
    },
    {
      id: 2,
      title: 'ESTIMATE DESIGN',
      description:
        'Lorem ipsum dolor sit amet, cons aring elit sed dllao the eimod tempor inciunt ullaco laboris aliquip alora.',
      img: SERVICE1,
      direction: isMobile ? '' : 'reverse',
    },
    {
      id: 3,
      title: 'BUILD DESIGN',
      description:
        'Lorem ipsum dolor sit amet, cons aring elit sed dllao the eimod tempor inciunt ullaco laboris aliquip alora.',
      img: SERVICE1,
      direction: '',
    },
  ];

  return (
    <div>
      <PageHeader title={'ABOUT US'} image={HEADER} />
      <Row>
        <Col className='about-us-page' xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <div className='about-us-page-container'>
            <div className='introduction-container'>
              <HeadingElement text="Introduction" />
              <div className='introduction-title'>
                How we became best among others?
              </div>
              <div className='introduction-description'>
                Magna voluptatum dolorem! Dolores! Sociosqu commodo nobis
                imperdiet lacinia? Magni! Felis, elementum nobis imperdiet
                lacinia nobis imperdiet lacinia.
              </div>
            </div>
            <div className='quote-container'>
              <div className='quote-title'>
                “We built Beautifully with the best possible materials and
                resources”
              </div>
              <div className='quote-subtitle'>- HENRY SCOTT, CEO</div>
              <ul className='quote-description-list'>
                <li>Quality Control System, 100% Satisfaction Guarantee</li>
                <li>Highly Professional Staff, Accurate Testing Processes</li>
                <li>Unrivalled workmanship, Professional and Qualified</li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='offers-holder' xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <div className='offers-container'>
            {offers.map((item) => {
              console.log('sdadas => ', item);
              const data = item;
              return <OffersCard key={item.id} offer={data} />;
            })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <div className='aboutus-gallery'>
            <div className='leftImgHolder'>
              <img src={SERVICE1} alt='img' />
            </div>
            <div className='rightImgHolder'>
              <img src={SERVICE2} alt='img' />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='working-process-holder' xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <HeadingElement text="Feature Process" />
          <h1 className='working-process-title'>OUR WORKING PROCESS</h1>
          <div className='working-process-description'>
            Magna voluptatum dolorem! Dolores! Sociosqu commodo nobis imperdiet
            lacinia? Magni! Felis, elementum nobis imperdiet lacinia nobis
            imperdiet lacinia.
          </div>
          <div className='working-process-container-card-holder'>
            {processes.map((item) => (
              <WorkingProcessCard key={item.id} process={item} />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
