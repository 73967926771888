import "./BasicFooter.scss";

import { Col, Row } from "antd";
import React from "react";

import LOGO from "../../../assets/images/default/logo.png";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";

const BasicFooter = () => {
  const navigate = useNavigate();

  return (
    <div className="footer-container">
      <Row>
        <Col
          className="footer-component-col"
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 16, offset: 4 }}
        >
          <div className="footer-info">
            <div className="footer-logo-container">
              <img src={LOGO} alt="logo" />
            </div>
            <div className="footer-desc-container">
              Lorem ipsum dolor sit amet, cons aring elit sed dllao the eimod
              tempor inciunt ullaco laboris aliquip alora.
            </div>
            <div className="footer-social-container">
              <FacebookOutlined />
              <InstagramOutlined />
              <LinkedinOutlined />
            </div>
          </div>
          <div className="footer-contact">
            <div className="footer-contact-title">CONTACT INFORMATION</div>
            <div className="footer-contact-data">Phone: +1(456)657-887</div>
            <div className="footer-contact-data">Mail: info@domain.com</div>
            <div className="footer-contact-data">
              Address: 3557 Derek Drive, Orlando, Florida
            </div>
          </div>
          <div className="footer-links">
            {" "}
            <div className="footer-link-title">USEFUL LINKS</div>
            <div className="footer-link-data">
              <span onClick={() => navigate('/about')}>About us</span>
            </div>
            <div className="footer-link-data">
              <span onClick={() => navigate('/gallery')}>Gallery</span>
            </div>
            <div className="footer-link-data">
              <span onClick={() => navigate('/contact')}>Contact</span>
            </div>
          </div>
          {/* <div className="footer-gallery"> GALLERY </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default BasicFooter;
