import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'sr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: { 
      sr: {
        translation: {
          menu: {
            home: 'Početna',
            about: 'O Nama',
            contact: 'Kontakt',
            services: 'Usluge',
            gallery: 'Galerija'
          },
          home: {
            aboutSection: {
              title: 'KAKO SMO POSTALI NAJBOLJI MEĐU DRUGIMA?',
              subtitle: 'Izgradili smo prelepo sa najboljim mogućim materijalima i resursima',
              text: 'U vreme velikih nevolja, himenej drže oni. Rezultat Rutrumovog laskanja, telo ga optužuje da ga mrzi strelama! Na meti je i osvetoljubivost, ali greška velike želje zaposlenog. Čas je čitan po pravu istine, iako je rečeno da je odbačen. razvoj',
              list: {
                item1: 'Sistem kontrole kvaliteta, 100% garancija na zadovoljstvo',
                item2: 'Visoko profesionalno osoblje, tačni procesi testiranja',
                item3: 'Izrada bez premca, profesionalna i kvalifikovana',
              },
              button: "Više o nama"
            }
          },
          aboutUs: {
            description: "Masinska obrada metala MIRMAT osnovana je 2016 godine, u pocetku se bavila masinskom obradom metala, ali ubrzo 2018 preorijentisala se na proizvodnju turbulatora i puznih transportera kao primarnu delatnost, pored navedenog pruza usluge laserskog secenja i graviranja (markiranja) na najnovijim Fiber laserima. Nas tim cine iskusni i pouzdani strucnjaci koji ce ispuniti zahteve klijenata i rese sve izazove koji se pred njih postave.",
            offerTitle1: "Misija",
            offerDescription1: "Pruziti industriji napredna, kvalitetna resenja i tehnoloski razvoj koji povecava produktivnost i efikasnost nasih klijenata kroz posvecenu radnu snagu, orijentisanu na postizanje licnog i ekonomskog prosperiteta razvijanjem produktivnog i bezbednog posla.",
            offerTitle2: "Vizija",
            offerDescription2: "Da postanemo liderska kompanija na nasim prostorima, dostupna nasim klijentima i dobavljacima kroz odgovoran, obucen i posvecen timski rad. Da imamo standardizovane i efikasne sisteme rada koji nam omogucavaju da budemo visoko produktivni, kao i maksimalnu posvecenost u konstantnom unapredjivanju usluga i naseg timskog rada.",
            offerTitle3: "test",
            offerDescription3: "test opis",
          },
          qa: {
            question1: "Kako smo postali najbolji?",
            answer1: "Godinama smo sticali iskustvo, obogacivali znanje i isli u korak sa novim inovacijama kao sto to cinimo i danas.",
            question2: "Zasto izabrati nas? ",
            answer2: "Zato sto se profesionalno i odgovorno ophodimo prema svakom projektu bez obzira na njegovu velicinu i zahtevnost.",
            question3: "Sta nudimo?",
            answer3: "Postovanje, iskustvo, profesionalnost, odgovornost, garanciju na kvalitet.",
            question4: "Kako Vam pruzamo usluge?",
            answer4: "Biramo najkvalitetnije materijale za vas, nakon cega se vrsi priprema materijala i opreme, zatim sam proces izrade i na kraju kontrola kvaliteta kako bi smo vam pruzili nabolje od najboljeg.",
          },
          service: {
            serviceTitle1: "Masinska obrada metala",
            serviceShortDescription1: "Pruzamo masinsku obradu metala kroz izradu prototipskih resenja kao i serijske prizvodnje koriscenjem CNC glodalica.",
            serviceDescription1: "Pruzamo masinsku obradu metala kroz izradu prototipskih resenja kao i serijske prizvodnje koriscenjem CNC glodalica, univerzalnih glodalica kao i strugova obradne povrsine do Fi800mm i duzine 3000mm. Delove izradjujemo prema tehnickoj dokumentaciji ili uzorku.",
            serviceTitle2: "Lasersko secenje",
            serviceShortDescription2: "Sa nasim najnovijim FIBER laserom mozete ocekivati zavrsne proizvode visokog kvaliteta i visoke tolerancije.",
            serviceDescription2: "Sa nasim najnovijim FIBER laserom mozete ocekivati zavrsne proizvode visokog kvaliteta i visoke tolerancije. Oslanjamo se na najmoderniju tehnologiju kako bi garantovali visok kvalitet i preciznost u svakom koraku proizvodnog procesa. Visok nivo profesionalizma garantuje da svaki proizvod ili uslugu isporucujemo u najkracem roku sa najvisim standardima kvaliteta. Posedujemo laser radne povrsine 1500x3000 snage 6kw.",
            serviceTitle3: "Lasersko graviranje",
            serviceShortDescription3: "Lasersko graviranje na metalu, sa nasim najnovijim FIber laserom postize se detaljna, precizna i dugotrajna gravura.",
            serviceDescription3: "Lasersko graviranje na metalu, sa nasim najnovijim FIber laserom postize se detaljna, precizna i dugotrajna gravura. Cesto se koristi za graviranje serijskih brojeva, oznaka, logotipa i drugih informacija na proizvodima od metala, kao i mogucnost graviranja fotografije. Ono sto odlikuje fiber lasersko graviranje u odnosu na ostale metode su VISOKA preciznost, tavnost i brzina u odnosu na druge metode graviranja. Takodje mozemo gravirati razlicite vrste materijla celik, nerdjajuci celik, aluminijum, bakar, zlato, srebro kao i kamen.",
          }
        }
      },
      en: {
        translation: {
          menu: {
            home: 'Home',
            about: 'About Us',
            contact: 'Contact',
            services: 'Services',
            gallery: 'Gallery'
          },
          home: {
            aboutSection: {
              title: 'HOW WE BECAME BEST AMONG OTHERS?',
              subtitle: 'We Built Beautifully With The Best Possible Materials And Resources',
              text: 'Nibh maiores molestiae tempore hymenaeos tenetur. Rutrum blanditiis consequuntur, corporis in accusamus odit in, sagittis! Ultrices, sed error magnam molestie cupidatat etiam, iaculis. Lectus class iure veritatis, cumque repudiandae dictumst. development.',
              list: {
                item1: 'Quality Control System, 100% Satisfaction Guarantee',
                item2: 'Highly Professional Staff, Accurate Testing Processes',
                item3: 'Unrivalled workmanship, Professional and Qualified',
              },
              button: "More about us"
            }
          },
          aboutUs: {
            description: "Masinska obrada metala MIRMAT osnovana je 2016 godine, u pocetku se bavila masinskom obradom metala, ali ubrzo 2018 preorijentisala se na proizvodnju turbulatora i puznih transportera kao primarnu delatnost, pored navedenog pruza usluge laserskog secenja i graviranja (markiranja) na najnovijim Fiber laserima. Nas tim cine iskusni i pouzdani strucnjaci koji ce ispuniti zahteve klijenata i rese sve izazove koji se pred njih postave.",
            offerTitle1: "Misija",
            offerDescription1: "Pruziti industriji napredna, kvalitetna resenja i tehnoloski razvoj koji povecava produktivnost i efikasnost nasih klijenata kroz posvecenu radnu snagu, orijentisanu na postizanje licnog i ekonomskog prosperiteta razvijanjem produktivnog i bezbednog posla.",
            offerTitle2: "Vizija",
            offerDescription2: "Da postanemo liderska kompanija na nasim prostorima, dostupna nasim klijentima i dobavljacima kroz odgovoran, obucen i posvecen timski rad. Da imamo standardizovane i efikasne sisteme rada koji nam omogucavaju da budemo visoko produktivni, kao i maksimalnu posvecenost u konstantnom unapredjivanju usluga i naseg timskog rada.",
            offerTitle3: "test",
            offerDescription3: "test opis",
          },
          qa: {
            question1: "Kako smo postali najbolji?",
            answer1: "Godinama smo sticali iskustvo, obogacivali znanje i isli u korak sa novim inovacijama kao sto to cinimo i danas.",
            question2: "Zasto izabrati nas? ",
            answer2: "Zato sto se profesionalno i odgovorno ophodimo prema svakom projektu bez obzira na njegovu velicinu i zahtevnost.",
            question3: "Sta nudimo?",
            answer3: "Postovanje, iskustvo, profesionalnost, odgovornost, garanciju na kvalitet.",
            question4: "Kako Vam pruzamo usluge?",
            answer4: "Biramo najkvalitetnije materijale za vas, nakon cega se vrsi priprema materijala i opreme, zatim sam proces izrade i na kraju kontrola kvaliteta kako bi smo vam pruzili nabolje od najboljeg.",
          },
          service: {
            serviceTitle1: "Masinska obrada metala",
            serviceDescription1: "Pruzamo masinsku obradu metala kroz izradu prototipskih resenja kao i serijske prizvodnje koriscenjem CNC glodalica, univerzalnih glodalica kao i strugova obradne povrsine do Fi800mm i duzine 3000mm. Delove izradjujemo prema tehnickoj dokumentaciji ili uzorku.",
            serviceTitle2: "Lasersko secenje",
            serviceDescription2: "Sa nasim najnovijim FIBER laserom mozete ocekivati zavrsne proizvode visokog kvaliteta i visoke tolerancije. Oslanjamo  se na najmoderniju tehnologiju kako bi garantovali visok kvalitet i preciznost u svakom koraku proizvodnog procesa. Visok nivo profesionalizma garantuje da svaki proizvod ili uslugu isporucujemo u najkracem roku sa najvisim standardima kvaliteta. Posedujemo laser radne povrsine 1500x3000 snage 6kw.",
            serviceTitle3: "Lasersko graviranje",
            serviceDescription3: "Lasersko graviranje na metalu, sa nasim najnovijim FIber laserom postize se detaljna, precizna i dugotrajna gravura. Cesto se koristi za graviranje serijskih brojeva, oznaka, logotipa i drugih informacija na proizvodima od metala, kao i mogucnost graviranja fotografije. Ono sto odlikuje fiber lasersko graviranje u odnosu na ostale metode su VISOKA preciznost, tavnost i brzina u odnosu na druge metode graviranja. Takodje mozemo gravirati razlicite vrste materijla celik, nerdjajuci celik, aluminijum, bakar, zlato, srebro kao i kamen.",
          }
        }
      },
    }
  });

export default i18n;