import "./GalleryPage.scss";

import PageHeader from "../../../../components/PageHeader/PageHeader";
import HEADER from "../../../../assets/images/default/header_image.jpg";
import { useNavigate } from "react-router";
import { Col, Image, Row } from "antd";

const GalleryPage = () => {
  const navigate = useNavigate();

  const albums = [
    {
      id: 1,
      title: "Album 1",
      url: "album1",
      cover_photo:
        "https://www.fastems.com/wp-content/uploads/2021/08/AdobeStock_172041052-scaled.jpeg",
    },
    {
      id: 2,
      title: "Album 2",
      url: "album2",
      cover_photo: "https://tdhmfg.com/wp-content/uploads/2023/02/blog-cnc.jpg",
    },
    {
      id: 3,
      title: "Album 3",
      url: "album3",
      cover_photo: "https://img.freepik.com/free-photo/modern-cnc-lathes-metalworking-industry_587448-4985.jpg?w=2000",
    },
  ];

  return (
    <div className="gallery-page">
      <PageHeader title={"GALLERY"} image={HEADER} />
      <Row>
        <Col
          className="menu-containe-col"
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 16, offset: 4 }}
        >
          <div className="gallery-container">
            {albums
              ? albums.map((album) => {
                  return (
                    <div key={album.id} onClick={() => navigate(album.url)}>
                      <p>{album.title}</p>
                      <Image src={album.cover_photo} fallback={'https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg'}/>
                    </div>
                  );
                })
              : "Loading"}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GalleryPage;
