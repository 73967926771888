import "./BasicHeader.scss";

import React, { useState } from "react";
import HeaderInfo from "../../../components/HeaderInfo/HeaderInfo";
import { useTranslation } from "react-i18next";
import {
  PhoneFilled,
  MailFilled,
  EnvironmentFilled,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";

import LOGO from "../../../assets/images/default/natpis.png";
import SERBIAN from "../../../assets/images/localization/serbia.png";
import ENGLISH from "../../../assets/images/localization/english.png";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

const lngs = {
  sr: { nativeName: "Srpski" },
  en: { nativeName: "English" },
};

const BasicHeader = () => {
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [showNav, setShowNav] = useState(false);

  const toggleNavItems = () => {
    setShowNav(!showNav);
  };

  const menuItems = [
    {
      id: 1,
      title: `${t("menu.home")}`,
      url: "",
    },
    {
      id: 2,
      title: `${t("menu.about")}`,
      url: "/about",
    },
    {
      id: 3,
      title: `${t("menu.gallery")}`,
      url: "/gallery",
    },
    {
      id: 4,
      title: `${t("menu.contact")}`,
      url: "/contact",
    },
  ];

  const navigatePage = (item) => {
    navigate(item.url);
    toggleNavItems();
  };

  return (
    <div className="basic-header">
      <div className="top-header">
        <Row>
          <Col
            className="top-header-col"
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 16, offset: 4 }}
          >
            <div
              className="mobile-menu-button"
              onClick={() => toggleNavItems()}
            >
              <MenuUnfoldOutlined />
            </div>
            <div className="logo-container">
              {" "}
              <img src={LOGO} alt="logo" />
            </div>
            <div className="info-container">
              <HeaderInfo
                icon={<PhoneFilled />}
                title="(+984) 256 897 22"
                subtitle="Phone number"
              />
              <HeaderInfo
                icon={<MailFilled />}
                title="info@domain.com"
                subtitle="Email address"
              />
              <HeaderInfo
                icon={<EnvironmentFilled />}
                title="San Andreas, USA"
                subtitle="Address location"
              />
            </div>
            <div className="localization-container">
              {Object.keys(lngs).map((lng) => (
                <img
                  key={lng}
                  className={i18n.resolvedLanguage === lng ? "language-icon active" : "language-icon"}
                  onClick={() => i18n.changeLanguage(lng)}
                  src={lngs[lng].nativeName === "Srpski" ? SERBIAN : ENGLISH}
                  alt={lng}
                />
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <div
        className={
          isMobile
            ? showNav
              ? "menu-container mobile-view open-mobile"
              : "menu-container mobile-view"
            : "menu-container"
        }
      >
        <div className="mobile-menu-button-close" onClick={() => toggleNavItems()}>
          <MenuFoldOutlined />
        </div>
        <Row>
          <Col
            className="menu-containe-col"
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 16, offset: 4 }}
          >
            <div className="menu-items-container">
              {menuItems.map((item) => {
                return (
                  <div
                    className="menu-item"
                    key={item.id}
                    onClick={() => navigatePage(item)}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div></div>
            <div className="menu-social-media-container">
              <FacebookOutlined />
              <InstagramOutlined />
              <LinkedinOutlined />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BasicHeader;
