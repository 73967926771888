import { RightOutlined } from "@ant-design/icons";
import "./FAQ.scss";

import { Col, Collapse, Row } from "antd";

import React from "react";
import ContactForm from "../../../../../../components/ContactForm/ContactForm";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const FAQ = () => {
   const { t } = useTranslation();

  return (
    <div className="faq-component">
      <Row>
        <Col className="menu-containe-col"  xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <div className="faq-main-container">
            <div className="faq-container">
              <div className="faq-title">
                CHECKOUT FREQUENTLY ASKED QUESTIONS
              </div>
              <div className="faq-description">
                Magna voluptatum dolorem! Dolores! Sociosqu commodo nobis
                imperdiet lacinia? Magni! Felis, elementum nobis imperdiet
                lacinia nobis imperdiet lacinia imperdiet lacinia nobis
                imperdiet lacinia nobis.
              </div>
              <div className="faq-collapse-container">
                <Collapse
                  accordion
                  expandIcon={({ isActive }) => (
                    <RightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Panel header={t("qa.question1")} key="1">
                    <p>{t("qa.answer1")}</p>
                  </Panel>
                  <Panel header={t("qa.question2")} key="2">
                    <p>{t("qa.answer2")}</p>
                  </Panel>
                  <Panel header={t("qa.question3")} key="3">
                    <p>{t("qa.answer3")}</p>
                  </Panel>
                  <Panel header={t("qa.question4")} key="4">
                    <p>{t("qa.answer4")}</p>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="faq-contact-main-container">
              <ContactForm
                title="ANY QUESTIONS? ASK US!!"
                buttonText="SUBMIT QUESTION"
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FAQ;
